import { useQueryClient } from "@tanstack/vue-query";
import { computed, unref, type MaybeRef } from "vue";
import useNotify from "~/composables/useNotify";
import { getErrorMessagesFromError } from "~/utils/helpers";
import { useOurNuxtApp } from "~/utils/nuxt";
import { QUERY_KEYS } from "~/utils/queryKeys";
import {
  createMutation,
  createQuery,
  invalidateQueries,
  type MyQueryOptions,
} from "~/utils/queryUtils";
import { parseUriTemplate } from "~/utils/uriTemplates";

import type { ListRulesQuery } from "~/server/api/hcd/libraries/[id]/rules/index.get";
import type { AddRulePayload } from "../models/Library/Rule.model";
import type {
  RuleViewModel,
  TemplatedRuleViewModel,
} from "../models/Library/Rule.model";
import { InvalidationKeys } from "~/utils/queryInvalidators";

type ListLibraryRulesBody = {
  id?: string;
  pageSize?: number;
  page?: number;
  search?: string;
  enabledOnly?: string | null;
  sortBy?: string;
  sortOrder?: string;
};

export type UpsertTemplatedRulePayload = Omit<
  TemplatedRuleViewModel,
  "isReadOnly" | "enabled" | "id"
>;

type WithNullableId<T> = Omit<T, "id"> & { id?: null };

export type UpsertRulePayload = WithNullableId<
  Omit<RuleViewModel, "enabled" | "isReadOnly">
>;

export type UpsertAnyRulePayload =
  | UpsertTemplatedRulePayload
  | UpsertRulePayload;

const endpoints = {
  list: parseUriTemplate("/api/hcd/libraries/{id}/rules"),
  enable: parseUriTemplate("/api/hcd/libraries/{id}/rules/{ruleId}/enable"),
  disable: parseUriTemplate("/api/hcd/libraries/{id}/rules/{ruleId}/disable"),
  rule: {
    get: parseUriTemplate("/api/hcd/libraries/{id}/rules/{ruleId}"),
    update: parseUriTemplate("/api/hcd/libraries/{id}/rules/{ruleId}"),
    create: parseUriTemplate("/api/hcd/libraries/{id}/rules"),
    delete: parseUriTemplate("/api/hcd/libraries/{id}/rules/{ruleId}"),
  },
} as const;

export const useRuleService = () => {
  const {
    $api,
    $i18n: { t },
  } = useOurNuxtApp();
  const { notifyError, notifySuccess } = useNotify();
  const queryClient = useQueryClient();

  const listLibraryRules = (
    { id, ...query }: ListLibraryRulesBody,
    signal?: AbortSignal
  ) =>
    $api(endpoints.list.expand({ id }), {
      signal,
      query: query satisfies ListRulesQuery,
    });

  const useListLibraryRulesQuery = (
    req: MaybeRef<ListLibraryRulesBody>,
    createNuxtError = true
  ) =>
    createQuery(
      [QUERY_KEYS.Libraries.Rules.list, req],
      ({ signal }) => listLibraryRules(unref(req), signal),
      {
        createNuxtError,
      }
    );

  const setRuleStatus = (id: string, ruleId: string, enable: boolean) => {
    const route = enable ? endpoints.enable : endpoints.disable;
    return $api(route.expand({ id, ruleId }), {
      method: "PUT",
    });
  };

  const useSetRuleStatusMutation = () =>
    createMutation(
      ({
        ruleId,
        enable,
        libraryId,
      }: {
        ruleId: string;
        enable: boolean;
        libraryId: string;
      }) => setRuleStatus(libraryId, ruleId, enable),
      {
        onSuccess: (_, { ruleId: id, libraryId, enable }) => {
          invalidateQueries(
            InvalidationKeys.Library.Rule.update(libraryId, id),
            queryClient
          );
          notifySuccess(
            t("success.success"),
            enable ? t("success.rule_enabled") : t("success.rule_disabled")
          );
        },
        onError: (_error, rule) =>
          rule.enable
            ? notifyError(t("error.rule_enabled"))
            : notifyError(t("error.rule_enabled")),
      }
    );

  const getRule = async (id: string, ruleId: string, signal?: AbortSignal) =>
    $api(endpoints.rule.get.expand({ id, ruleId }), {
      signal,
    });

  function useGetRuleQuery<Sus extends boolean = true>(
    id: MaybeRef<string>,
    ruleId: MaybeRef<string>,
    options?: MyQueryOptions<Sus>
  ) {
    const queryKey = computed(() => {
      return [QUERY_KEYS.Libraries.Rules.get, unref(id), unref(ruleId)];
    });

    return createQuery(
      queryKey,
      ({ signal }) => getRule(unref(id), unref(ruleId), signal),
      options
    );
  }

  const updateRule = (
    id: string,
    ruleId: string,
    payload: AddRulePayload,
    signal?: AbortSignal
  ) =>
    $api(endpoints.rule.update.expand({ id, ruleId }), {
      headers: {
        "content-type": "application/json",
      },
      method: "PUT",
      body: payload,
      signal: signal,
    });

  const useUpdateRuleMutation = () =>
    createMutation(
      ({
        libraryId,
        ruleId,
        payload,
      }: {
        libraryId: string;
        ruleId: string;
        payload: AddRulePayload;
      }) => updateRule(libraryId, ruleId, payload),
      {
        onSuccess: (_x, y) => {
          invalidateQueries(
            InvalidationKeys.Library.Rule.update(y.libraryId, y.ruleId),
            queryClient
          );
          notifySuccess(t("success.success"), t("success.rule_updated"));
        },
        onError: (error) => {
          notifyError(getErrorMessagesFromError(error).join("\n"));
        },
      }
    );

  const createRule = (
    id: string,
    payload: AddRulePayload,
    signal?: AbortSignal
  ) =>
    $api(endpoints.rule.create.expand({ id }), {
      headers: {
        "content-type": "application/json",
      },
      method: "POST",
      body: payload,
      signal: signal,
    });

  const useCreateRuleMutation = () =>
    createMutation(
      ({ id, payload }: { id: string; payload: AddRulePayload }) =>
        createRule(id, payload),
      {
        onSuccess: (_, { id }) => {
          invalidateQueries(
            InvalidationKeys.Library.Rule.create(id),
            queryClient
          );
          notifySuccess(t("success.success"), t("success.rule_updated"));
        },
        onError: (e) => {
          notifyError(
            t("error.rule_update"),
            getErrorMessagesFromError(e).join("\n")
          );
        },
      }
    );

  const deleteRule = (id: string, ruleId: string, signal?: AbortSignal) =>
    $api(endpoints.rule.delete.expand({ id, ruleId }), {
      method: "DELETE",
      signal: signal,
    });

  const useDeleteRuleMutation = () =>
    createMutation(
      ({ libraryId, ruleId }: { libraryId: string; ruleId: string }) =>
        deleteRule(libraryId, ruleId),
      {
        onSuccess: (_, y) => {
          invalidateQueries(
            InvalidationKeys.Library.Rule.delete(y.libraryId),
            queryClient
          );
          notifySuccess(t("success.success"), t("success.rule_delete"));
        },
        onError: () => notifyError(t("error.rule_delete")),
      }
    );

  return {
    listLibraryRules,
    useListLibraryRulesQuery,
    useGetRuleQuery,
    useSetRuleStatusMutation,
    setRuleStatus,

    useCreateRuleMutation,
    createRule,

    useUpdateRuleMutation,
    updateRule,

    useDeleteRuleMutation,
  };
};
